$body-text-color: #333;

$bg-light: #fafafa;
$bg-dark: #f2f2f2;
$bg-lightest: #fff;

$section-border: #fff;

$category-header-bg: #1a1a1a;
$category-header-text: #f2f2f2;

$category-header-keyline: #fbfbfb;
$category-header-keyline-alt: #eaeaea;

$category-subheader-label-text: #808080;

$icon-color: #808080; // visually the same as $body-text-color
$icon-color-sot: #000;

$color-black: #1a1a1a;
$color-red: #e60000;
$color-cyan: #36b4e1;
$color-teal: #36cbb5;
$color-purple: #7e11ff;
$color-orange: #ff931e;
$color-blue: #0071db;
$color-magenta: #ed1e98;
$color-gold: #ca9052;
$color-pale-mint: #b9cccc;
$color-pink: #9e38a3;

$section-color-youtube: $color-red;
$section-color-courses: $color-cyan;
$section-color-languages: $color-teal;
$section-color-thinking: $color-purple;
$section-color-programming: $color-orange;
$section-color-books: $color-blue;
$section-color-music: $color-magenta;
$section-color-general: $color-gold;
$section-color-howto: $color-pale-mint;
$section-color-podcasts: $color-pink;

$footer-text: #999;
$footer-link-hover: #BBB;
$footer-keyline: #232323;
$copy-text-color: #666;

$button-color-disabled: #BBB;

$tap-alpha: .05;
$row-hover-alt: change-color($icon-color-sot, $lightness: 93);
$row-hover: #F6F6F6;
