#main-footer {
  padding: $site-gutter-width 0 0 0;
  background: $category-header-bg;
  color: $footer-text;

  .viewport {
    display: block;
  }

  a, a:visited, a:active {
    color: $footer-text;
  }

  small {
    display: block;
    margin: 1em 0 1.5em 0;
    text-transform: uppercase;
    a, strong {
      margin-right: 1em;
      white-space: nowrap;
    }
    div div {
      display: inline-block;
    }
  }
}

.footer-block {
  border-bottom: 2px solid $footer-keyline;

  svg {
    max-width: 75%;
  }
}

.footer-links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5em;

  h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1em;
    padding-right: 1em;
  }

  a {
    display: block;
    line-height: 1.75em;
  }
  a:hover {
    color: $footer-link-hover;
    text-decoration: underline;
  }
}

.footer-copy {
  background: $footer-keyline;
  color: $copy-text-color;
  font-size: $footer-copy-size;
  padding: 0.5em 0;

  svg {
    width: 6.5em;
  }
}

@media only screen and (max-width: $site-width-collapse-footer) {
  .footer-links {
    display: block;
  }
  .footer-block svg {
    max-width: 100%;
  }
}
