$site-header-height: 289 * $design-scaling;
$site-header-natural-width: 385 * $design-scaling;

#site-header {
  color: white;
  position: relative;
  overflow: hidden;
  background: url(~Images/SoT_FLL_BackgroundHeader.jpg) center center;
  background-size: cover;

  .viewport {
    position: relative;
    z-index: 1;
    // height: 0;
    align-items: center;
  }

  .hero {
    margin: 0;
    height: 0;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);

    @supports (object-fit: cover) {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-width: auto;
      max-width: none;
      transform: none;
      object-fit: cover;
      object-position: center center;
    }
  }
}

.hero-logo {
  position: relative;
  width: 100%;
  padding-bottom: ($site-header-height / $site-header-natural-width) * 100%;
}

.hero-img {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  svg {
    position: relative;
    top: 50%;
    height: 100px;
    width: 100%;
    margin-top: -10%;
  }
}
