// section-specific hover styles for links & link icons

.hasHover {
  a:hover {
    color: $icon-color-sot;
  }

  a.sot:hover {
    svg {
      stroke: $icon-color-sot;
    }
  }
  a.youtube:hover {
    svg {
      stroke: $section-color-youtube;
    }
  }
  a.courses:hover {
    svg {
      stroke: $section-color-courses;
    }
  }
  a.languages:hover {
    svg {
      stroke: $section-color-languages;
    }
  }
  a.thinking:hover {
    svg {
      stroke: $section-color-thinking;
    }
  }
  a.programming:hover {
    svg {
      stroke: $section-color-programming;
    }
  }
  a.books:hover {
    svg {
      stroke: $section-color-books;
    }
  }
  a.music:hover {
    svg {
      stroke: $section-color-music;
    }
  }
  a.general:hover {
    svg {
      stroke: $section-color-general;
    }
  }
  a.howto:hover {
    svg {
      stroke: $section-color-howto;
    }
  }
  a.podcasts:hover {
    svg {
      stroke: $section-color-podcasts;
    }
  }
}
