.interstital {
  background: $bg-dark;
  text-align: center;
  padding: $preview-bottom-pad 0;

  img {
    max-width: 100%;
  }

  > .content {
    padding: 0 12px;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;

    > div + div {
      margin-top: 1.5rem;

      &.button {
        margin-top: 2.5rem;
      }
    }
  }
}
