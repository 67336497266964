.button,
a.button {
  box-sizing: border-box;
  position: relative;
  transition: background 0.3s;
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 3.9px;
  height: $category-more-buttonheight;
  min-width: $category-more-buttonwidth;
  color: $category-header-text;
  border: 0;
  border-radius: 3px * $design-scaling;
  padding-right: 2rem;
  padding-left: 2rem;

  // Theme
  background-color: $color-black;

  &:hover {
    color: $category-header-text !important;
    background-color: darken($color-black, 7%);
  }

  & &--black {
    background-color: $color-black;

    &:hover {
      background-color: darken($color-black, 7%);
    }
  }

  &--red {
    background-color: $color-red;

    &:hover {
      background-color: darken($color-red, 7%);
    }
  }

  &--cyan {
    background-color: $color-cyan;

    &:hover {
      background-color: darken($color-cyan, 7%);
    }
  }

  &--teal {
    background-color: $color-teal;

    &:hover {
      background-color: darken($color-teal, 7%);
    }
  }

  &--purple {
    background-color: $color-purple;

    &:hover {
      background-color: darken($color-purple, 7%);
    }
  }

  &--orange {
    background-color: $color-orange;

    &:hover {
      background-color: darken($color-orange, 7%);
    }
  }

  &--blue {
    background-color: $color-blue;

    &:hover {
      background-color: darken($color-blue, 7%);
    }
  }

  &--magenta {
    background-color: $color-magenta;

    &:hover {
      background-color: darken($color-magenta, 7%);
    }
  }

  &--gold {
    background-color: $color-gold;

    &:hover {
      background-color: darken($color-gold, 7%);
    }
  }

  &--pale-mint {
    background-color: $color-pale-mint;

    &:hover {
      background-color: ($color-pale-mint, 7%);
    }
  }

  &--pink {
    background-color: $color-pink;

    &:hover {
      background-color: darken($color-pink, 7%);
    }
  }
}

a.button {
  line-height: $category-more-buttonheight;
}
