$section-summary-box-width: 36px * $design-scaling;
$category-underline-width: 3px * $design-scaling;

$sot-header-sml-width: 350px * $design-scaling;

// main (dark BG) category header blocks

.category .entry-even header {
  background: white;
}

.category-header {
  background-color: $category-header-bg;
  color: $category-header-text;
  text-transform: uppercase;
  border-bottom-width: $category-underline-width;
  border-bottom-style: solid;

  &.youtube {
    border-bottom-color: $section-color-youtube;
  }
  &.courses {
    border-bottom-color: $section-color-courses;
  }
  &.languages {
    border-bottom-color: $section-color-languages;
  }
  &.thinking {
    border-bottom-color: $section-color-thinking;
  }
  &.programming {
    border-bottom-color: $section-color-programming;
  }
  &.books {
    border-bottom-color: $section-color-books;
  }
  &.music {
    border-bottom-color: $section-color-music;
  }
  &.general {
    border-bottom-color: $section-color-general;
  }
  &.howto {
    border-bottom-color: $section-color-howto;
  }
  &.podcasts {
    border-bottom-color: $section-color-podcasts;
  }
}

.category-header h2 {
  font-size: $category-header-fontsize;
  line-height: $category-header-height;
  font-weight: 400;
  margin: 0;
}

// right-hand metadata cells

.menubox {
  @include centered-content;
  width: $section-summary-box-width;
  flex-shrink: 0;
  border-color: $category-header-keyline-alt;
  border-left-width: 1px;
  border-left-style: solid;

  &:last-child {
    border-right-style: solid;
    border-right-width: 1px;
  }

  &.rating {
    font-size: $item-rating-fontsize;
    color: $category-subheader-label-text;
    padding-left: $section-summary-box-width;
    border-left: 0;
  }
}

.alt .menubox {
  border-color: $category-header-keyline;
}

.expander svg {
  width: $arrow-icon-size;
  height: $arrow-icon-size;
  transform: rotate(-90deg);
  stroke: $icon-color;
  fill: $icon-color;
}

// category subheader labels

.category-table-headers {
  font-size: 10px * $design-scaling;
  text-transform: uppercase;
  color: $category-subheader-label-text;

  .logo, .content, .menubox {
    margin: 1em 0;
  }

  .logo {
    width: $logo-max-size;
    padding-right: $section-left-pad - $logo-max-size;
    text-align: center;
  }
}

.category-table-headers .menubox {
  border-color: transparent;
}

// header link is positioned outside the flow for separate hover state
.header-link .menubox {
  position: absolute;
  z-index: 1;
  top: $section-min-height * -1;
  right: $section-summary-box-width * 3 + 2px;  // add 2px for borders
  height: $section-min-height;
  transition: background-color .3s;
}

// breakpoints for reducing header metabox widths

@media only screen and (max-width: $site-sml-width) {
  // to match .viewport layout adjustment... abs positioning needs to account for new padding
  .header-link .menubox {
    right: ($section-summary-box-width * 3) - ($site-gutter-width - $site-sml-gutter-width) + 2px;  // add 2px for borders
  }
}

@media only screen and (max-width: $site-width-hide-toplevel-rating) {
  .menubox.rating {
    display: none;
  }
  .header-link .menubox {
    right: ($section-summary-box-width * 2) - ($site-gutter-width - $site-sml-gutter-width) + 1px;  // add 1px for borders
  }
  header .menubox.expander {
    padding-left: $section-summary-box-width;
    border-left-width: 0;
  }
  .category-table-headers .menubox:nth-child(4) {
    display: none;
  }
  .header-sot .menubox.expander {
    padding-left: 0;
  }
}

@media only screen and (max-width: $site-width-hide-toplevel-links) {
  .header-link {
    display: none;
  }
  header .menubox.expander {
    padding-left: 0;
    border-left-width: 1px;
  }
  .category-table-headers .menubox:nth-child(3) {
    display: none;
  }
}

// background taps/hovers for header link

.hasHover {
  a.menubox:hover {
    background-color: $row-hover;
  }
  .alt a.menubox:hover {
    background-color: $row-hover-alt;
  }
}

// expander arrow hovers

header {
  transition: background-color .3s;

  // unsure if this is meant to be dark prior to opening. Probably not?
  // &.header-sot .expander svg {
  //   stroke: $icon-color-sot;
  //   fill: $icon-color-sot;
  // }
}

.hasHover article header {
  cursor: pointer;

  &:hover {
    background: $row-hover;
    .expander svg {
      transform: rotate(0deg);
    }
  }
  &.alt:hover {
    background: $row-hover-alt;
  }
}

.hasHover header {
  // per-section arrow hover colouring (part 1)

  &.header-sot:hover {
    .expander svg {
      stroke: $icon-color-sot;
      fill: $icon-color-sot;
    }
  }
  &.header-youtube:hover {
    .expander svg {
      stroke: $section-color-youtube;
      fill: $section-color-youtube;
    }
  }
  &.header-courses:hover {
    .expander svg {
      stroke: $section-color-courses;
      fill: $section-color-courses;
    }
  }
  &.header-languages:hover {
    .expander svg {
      stroke: $section-color-languages;
      fill: $section-color-languages;
    }
  }
  &.header-thinking:hover {
    .expander svg {
      stroke: $section-color-thinking;
      fill: $section-color-thinking;
    }
  }
  &.header-programming:hover {
    .expander svg {
      stroke: $section-color-programming;
      fill: $section-color-programming;
    }
  }
  &.header-books:hover {
    .expander svg {
      stroke: $section-color-books;
      fill: $section-color-books;
    }
  }
  &.header-music:hover {
    .expander svg {
      stroke: $section-color-music;
      fill: $section-color-music;
    }
  }
  &.header-general:hover {
    .expander svg {
      stroke: $section-color-general;
      fill: $section-color-general;
    }
  }
  &.header-howto:hover {
    .expander svg {
      stroke: $section-color-howto;
      fill: $section-color-howto;
    }
  }
  &.header-podcasts:hover {
    .expander svg {
      stroke: $section-color-podcasts;
      fill: $section-color-podcasts;
    }
  }
}

.open header {
  .expander svg {
    transform: rotate(0deg);
  }

  // per-section arrow hover colouring (part 2)

  &.header-sot .expander svg {
    stroke: $icon-color-sot;
    fill: $icon-color-sot;
  }
  &.header-youtube .expander svg {
    stroke: $section-color-youtube;
    fill: $section-color-youtube;
  }
  &.header-courses .expander svg {
    stroke: $section-color-courses;
    fill: $section-color-courses;
  }
  &.header-languages .expander svg {
    stroke: $section-color-languages;
    fill: $section-color-languages;
  }
  &.header-thinking .expander svg {
    stroke: $section-color-thinking;
    fill: $section-color-thinking;
  }
  &.header-programming .expander svg {
    stroke: $section-color-programming;
    fill: $section-color-programming;
  }
  &.header-books .expander svg {
    stroke: $section-color-books;
    fill: $section-color-books;
  }
  &.header-music .expander svg {
    stroke: $section-color-music;
    fill: $section-color-music;
  }
  &.header-general .expander svg {
    stroke: $section-color-general;
    fill: $section-color-general;
  }
  &.header-howto .expander svg {
    stroke: $section-color-howto;
    fill: $section-color-howto;
  }
  &.header-podcasts .expander svg {
    stroke: $section-color-podcasts;
    fill: $section-color-podcasts;
  }
}

.hasHover .open header {
  .expander svg {
    transition: transform 0.5s;
  }
  &:hover .expander svg {
    transform: rotate(-180deg);
    // :NOTE: these !important decls are here to make dynamic category colour injection easier (see index template)
    stroke: $icon-color !important;
    fill: $icon-color !important;
  }
}

// responsive styles for special SoT section to prevent logo getting tiny

.header-sot .content {
  @include centered-content;
  justify-content: flex-start;
  position: relative;
  svg {
    width: 100%;
    max-width: 320px;
    margin-right: 0.5em;
  }
}

@media only screen and (max-width: $sot-header-sml-width) {
  .header-sot {
    .logo {
      display: none;
    }
  }
}

// article section footer (load more button)

.section-more {
  overflow-anchor: none;
  text-align: center;
  font-size: 1rem;
  padding: 48px 0;

  button {
    @extend .button;
    padding: 0.3em 1.6em 0.3em 2.0em;

    .ldr {
      display: block !important;
    }

    &.loading {
      cursor: default;
      .text { opacity: 0; }
      .ldr { display: block; }
    }
  }

  button {
    &.youtube {
      background: $section-color-youtube;
    }
    &.courses {
      background: $section-color-courses;
    }
    &.languages {
      background: $section-color-languages;
    }
    &.thinking {
      background: $section-color-thinking;
    }
    &.programming {
      background: $section-color-programming;
    }
    &.books {
      background: $section-color-books;
    }
    &.music {
      background: $section-color-music;
    }
    &.general {
      background: $section-color-general;
    }
    &.howto {
      background: $section-color-howto;
    }
    &.podcasts {
      background: $section-color-podcasts;
    }
  }

  button.disabled {
    cursor: default;
    background: $button-color-disabled;
  }
}

.hasHover button.youtube:hover {
  background: darken($section-color-youtube, 7%);
}
.hasHover button.courses:hover {
  background: darken($section-color-courses, 7%);
}
.hasHover button.languages:hover {
  background: darken($section-color-languages, 7%);
}
.hasHover button.thinking:hover {
  background: darken($section-color-thinking, 7%);
}
.hasHover button.programming:hover {
  background: darken($section-color-programming, 7%);
}
.hasHover button.books:hover {
  background: darken($section-color-books, 7%);
}
.hasHover button.music:hover {
  background: darken($section-color-music, 7%);
}
.hasHover button.general:hover {
  background: darken($section-color-general, 7%);
}
.hasHover button.howto:hover {
  background: darken($section-color-howto, 7%);
}
.hasHover button.podcasts:hover {
  background: darken($section-color-podcasts, 7%);
}

.hasHover button.disabled:hover {
  background: $button-color-disabled;
}
