$icon-size: 18px * $design-scaling;
$category-icon-size: 24px * $design-scaling;
$arrow-icon-size: 12px * $design-scaling;

.icon {
  position: relative;
  width: $icon-size;
  height: $icon-size;
  transition: fill 0.3s, stroke 0.3s, transform 0.3s;
  stroke: $icon-color;
}

.category-icon {
  position: relative;
  width: $category-icon-size;
  height: $category-icon-size;
}

.icon-info {
  top: 4px * $design-scaling; // aligns with the body copy nicely
}

.icon-link {
  width: $icon-size - (2 * $design-scaling);
  height: $icon-size - (2 * $design-scaling);
}

.icon-copyright {
  width: $icon-size - (3 * $design-scaling);
  height: $icon-size - (3 * $design-scaling);
}
