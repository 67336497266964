$design-scaling: 1.5;

@import "mixins";

@import "normalize";

@import "utilities";

@import "pallette";
@import "typography";

@import "layout";
@import "header";
@import "icons";
@import "button";

@import "category-headers";
@import "fields";
@import "article-headers";
@import "article-content";
@import "preview-images";
@import "interstital-ad";
@import "accordions";
@import "load-more";
@import "loading-anim";

@import "footer";

.visuallyhidden {
  border: 0;
  clip-path: inset(50%);
  display: inline-block;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap; /* 1 */
}

:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* ---------------------------------------------------------
   Print styles
   ---------------------------------------------------------*/
@media print {
    * {
        color:#000 !important;
        box-shadow:none !important;
        text-shadow:none !important;
        background:transparent !important;
    }
    /* Hide navigation */
    nav { display:none; }

    /* Show link destinations in brackets after the link text */
    a[href]:after { content: " (" attr(href) ") "; }
    a[href] {
        font-weight:bold;
        text-decoration:underline;
        border:none;
    }
    /* Don't show link destinations for JavaScript or internal links */
    a[href^="javascript:"]:after, a[href^="#"]:after { content:""; }

    /* Show abbr title value in brackets after the text */
    abbr[title]:after { content: " (" attr(title) ")"; }

    figure {
        margin-bottom:1em;
        overflow:hidden;
    }
}
