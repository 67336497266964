// breakpoints

$site-sml-width: 479px * $design-scaling;
$site-width-hide-toplevel-rating: 460px;
$site-width-hide-toplevel-links: 420px;

$site-width-collapse-footer: 510px;

// measurements

$site-content-width: (340px * $design-scaling) + 60px;
$site-gutter-width: 36px * $design-scaling;

$site-sml-gutter-width: 20px * $design-scaling; // more room at <480px

$section-min-height: 36px * $design-scaling;
$section-left-pad: 47px * $design-scaling;
$preview-bottom-pad: 32px * $design-scaling;

$logo-max-size: 28px * $design-scaling;

$content-row-margins: (10px * $design-scaling) 0 (13px * $design-scaling) 0;

// site viewport container elements

.viewport {
  position: relative;
  display: flex;
  align-items: stretch;
  max-width: $site-content-width;
  margin-left: auto;
  margin-right: auto;
  padding: 0 $site-gutter-width;

  // compensating for Barlow font alignment (I think?)
  p.content {
    position: relative;
    margin: $content-row-margins;
  }
  p.logo {
    margin: (7px * $design-scaling) 0 (8px * $design-scaling) 0;
  }
}

@media only screen and (max-width: $site-sml-width) {
  .viewport {
    padding: 0 $site-sml-gutter-width;
  }
}

// keylines between resources

article section {
  border-top: 2px solid $section-border;
  min-height: $section-min-height;
  margin-bottom: -2px;

  &:first-child {
    border-top: 0;
  }
  &:last-child {
    margin-bottom: $preview-bottom-pad;
  }
}

// article block background

article {
  background: $bg-light;
}

// left-hand logo sections & content block layout

.logo, .logo-img {
  @include centered-content;
  flex-shrink: 0;
  width: $logo-max-size;
  padding-right: $section-left-pad - $logo-max-size;
}

.logo.aligntop {
  align-items: flex-start;
}

.logo-img {
  img {
    width: 100%;
    max-width: $logo-max-size;
    max-height: $logo-max-size;
  }
}

.content {
  flex-grow: 1;

  svg {
    max-width: 100%;
  }
}

// logo block alignment for link rows with nested icon

.content a svg {
  position: absolute;
  width: $logo-max-size * 3;
  left: -($logo-max-size * 2 + ($section-left-pad - $logo-max-size));
  top: -1px;
}
