$body-font-size: 12px * $design-scaling;
$body-letter-spacing: 0.18px;

$category-header-fontsize: 20px * $design-scaling;
$category-header-height: 1.8em;

$category-more-fontsize: 13px * $design-scaling;
$category-more-buttonheight: 36px;
$category-more-buttonwidth: 200px;

$item-header-fontsize: 17px * $design-scaling;
$item-rating-fontsize: 15px * $design-scaling;

$footer-copy-size: 14px;

body, html {
  font-family: 'Barlow Condensed', sans-serif;
  color: $body-text-color;
  font-size: $body-font-size;
  letter-spacing: $body-letter-spacing;
  line-height: 1.3;
  background-color: $bg-light;
}

a {
  transition: color 0.3s;
}
a, a:visited, a:active, a:hover {
  text-decoration: none;
  color: $body-text-color;
}
.hasHover a:hover {
  color: $category-subheader-label-text
}
