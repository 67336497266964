.hide-label {
  span.label {
    display: none;
  }
}

button {
  background: #603691;
}

button:hover {
  background: darken(#603691, 10);
}
