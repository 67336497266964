$loader-icon-basesize: 9px * $design-scaling;
$loader-icon-dotsize: 6px;

.ldr {
  display: block;
  position: absolute;
  top: 0.35em;
  left: 2.2em;
  width: $category-icon-size;
  height: $category-icon-size;
}
.ldr div {
  position: absolute;
  top: $category-icon-size / 4;
  width: $loader-icon-dotsize;
  height: $loader-icon-dotsize;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldr div:nth-child(1) {
  left: $loader-icon-basesize;
  animation: ldr1 0.6s infinite;
}
.ldr div:nth-child(2) {
  left: $loader-icon-basesize;
  animation: ldr2 0.6s infinite;
}
.ldr div:nth-child(3) {
  left: $loader-icon-basesize * 4;
  animation: ldr2 0.6s infinite;
}
.ldr div:nth-child(4) {
  left: $loader-icon-basesize * 7;
  animation: ldr3 0.6s infinite;
}
@keyframes ldr1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ldr3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes ldr2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate($loader-icon-basesize * 3, 0);
  }
}
