$slider-nav-outset: $site-gutter-width;

// toplevel slider layout

.slick-slider {
  // adjust for box-sizing: border-box
  padding: 0;
}

.slick-slide {
  position: relative;
}

.preview img {
  display: block;
  max-width: 100%;
  border-radius: 3px * $design-scaling;
}

.viewport.slider {
  display: block;
}

// slider slide structure (with footer text)

.slider {
  a.slick-slide {
    display: block;
  }

  figure {
    margin: 0;
  }

  figcaption {
    position: relative;
    display: flex;
    padding: $content-row-margins;
    line-height: 1.6em;
  }

  .iconbox {
    @include centered-content;
    flex-shrink: 0;
    width: $logo-max-size;
    padding-right: $section-left-pad - $logo-max-size;
  }

  svg {
    left: 1px;
  }
}

// slider nav buttons

.slider .prev,
.slider .next {
  cursor: pointer;
  position: absolute;
  left: -$slider-nav-outset;
  width: $slider-nav-outset;
  top: 0;
  height: 100%;
  z-index: 1;

  .icon {
    top: 43.5%;
    margin-top: -0.5em;
    width: $arrow-icon-size;
    height: $arrow-icon-size;
    stroke: $icon-color-sot;
    fill: $icon-color-sot;
    transform: rotate(90deg);
  }
}

.hasHover .slider .prev,
.hasHover .slider .next {
  .icon {
    stroke: $icon-color;
    fill: $icon-color;
  }
  &:hover .icon {
    stroke: $icon-color-sot;
    fill: $icon-color-sot;
  }
}

.slider .next {
  right: -$slider-nav-outset;
  left: auto;
  text-align: right;

  .icon {
    transform: rotate(-90deg);
  }
}

@media only screen and (max-width: $site-sml-width) {
  .slick-slider {
    padding: 0 $site-sml-gutter-width;
  }
  .slider .prev {
    left: 0;
  }
  .slider .next {
    right: 0;
  }
}

// slider slide link text icon hovers

.hasHover a.sot:hover svg {
  stroke: $icon-color-sot;
}
.hasHover a.youtube:hover svg {
  stroke: $section-color-youtube;
}
.hasHover a.courses:hover svg {
  stroke: $section-color-courses;
}
.hasHover a.languages:hover svg {
  stroke: $section-color-languages;
}
.hasHover a.thinking:hover svg {
  stroke: $section-color-thinking;
}
.hasHover a.programming:hover svg {
  stroke: $section-color-programming;
}
.hasHover a.books:hover svg {
  stroke: $section-color-books;
}
.hasHover a.music:hover svg {
  stroke: $section-color-music;
}
.hasHover a.general:hover svg {
  stroke: $section-color-general;
}
.hasHover a.howto:hover svg {
  stroke: $section-color-howto;
}
.hasHover a.podcasts:hover svg {
  stroke: $section-color-podcasts;
}
